import React from 'react';
import LEFT_ARROW_ICON from 'images/arrow-left.svg';
import { Link } from 'gatsby';
import SlideUp from 'components/animation/SlideUp';
import reactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';
import CustomContainer from '../../CustomContainer';

const NewsDetails = ({ data }) => {
  const dateSplit = data?.date.split('-');
  const convertMonth = () => {
    const date = new Date();
    date.setMonth(Number(dateSplit[1]) - 1);
    return date
      .toLocaleString('en-US', {
        month: 'long',
      })
      .toUpperCase();
  };

  return (
    <div className="bg-brand-black text-brand-white font-light min-h-screen pt-[70px]">
      <CustomContainer className="pt-5 lg:pt-10 pb-24 relative">
        <Link to="/news">
          <div className="flex items-center cursor-pointer hover:opacity-50 duration-500 mt-5 lg:mt-0">
            <img
              src={LEFT_ARROW_ICON}
              alt="back-arrow"
              className="w-[35px] sm:w-[40px] mr-2"
            />
            <p className="text-sm sm:text-base font-thin">
              Return to News Page
            </p>
          </div>
        </Link>
        <div className="w-full xl:w-[1200px] ml-auto mr-auto">
          <SlideUp freeze={true}>
            {/* news header */}
            <div className="flex flex-row items-center mt-5 mb-10 xl:mb-5">
              <div className="flex flex-col text-center justify-center">
                <p className="text-sm line-clamp-1 w-full">{convertMonth()}</p>
                <p className="text-lg leading-tight">{dateSplit[2]}</p>
                <p className="text-sm">{dateSplit[0]}</p>
              </div>
              <p className="text-base sm:text-md xl:text-lg pl-5 sm:pl-10 xl:pl-20 xl:pr-16">
                {data.title}
              </p>
            </div>
          </SlideUp>
          {/* news content */}
          <div className="w-full flex flex-col items-center sm:items-end w-[100%] xl:w-[90%]">
            <img
              alt={data.title}
              src={data.src}
              title={data.title}
              className="w-full xl:w-[800px] h-[fit-content] sm:h-[400px] xl:h-[450px] object-cover"
            />
            <article className="mt-14 text-sm sm:text-[17px] font-thin leading-relaxed w-full xl:w-[800px]">
              {reactHtmlParser(data.content)}
              <br />
              <br />
              <a href={data.sourceURL} target="_blank" rel="noreferrer">
                <span>News source: </span>
                <span className="cursor-pointer hover:opacity-50 duration-300 hover:underline">
                  {data.source}
                </span>
              </a>
            </article>
          </div>
        </div>
      </CustomContainer>
    </div>
  );
};

NewsDetails.propTypes = {
  data: PropTypes.any,
};

export default NewsDetails;
