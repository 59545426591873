import { graphql } from 'gatsby';
import * as React from 'react';
import Pages from 'components/Pages';
import NewsDetails from 'components/pages/news/NewsDetails';

const NewsDetailsTemplate = ({ data }) => {
  return (
    <Pages>
      <NewsDetails data={data.newsJson} />
    </Pages>
  );
};

export default NewsDetailsTemplate;

export const query = graphql`
  query ($slug: String) {
    newsJson(slug: { eq: $slug }) {
      content
      date
      source
      sourceURL
      slug
      title
      src
    }
  }
`;
